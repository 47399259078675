<template>
  <div class="main-box" @click="historyShow = false">
    <a-breadcrumb class="aBreadcrumb">
      <a-breadcrumb-item
        ><router-link to="/"
          >首页</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">商城</a-breadcrumb-item>
    </a-breadcrumb>

    <!-- 关键词搜索 -->
    <div class="searchBtn">
      <div class="search-box" @click.stop="historyShow = true">
        <a-input placeholder="孤独症康复教育" v-model="paramsQuery.productName" @change.stop="clearInput" class="input" allowClear />
        <a-button type="primary" icon="search" :loading="loading" @click.stop="onButtonSearch()">搜索</a-button>
      </div>
      <!-- 历史查询面板 -->
      <div class="history-box" :class="{'history-box-open' : historyShow}" @click.stop="historyShow = true">
        <div style="padding:14px">
          <p class="title">热门搜索</p>
          <div class="label">
            <span class="label-item" @click.stop="onLabelClick(name)" :class="{'label-active' : name == paramsQuery.productName}" v-for="(name,index) in hotKeyList" :key="index">{{name}}</span>
          </div>
          
          <div class="history-title">
            <span class="title">搜索历史</span>
            <img @click.stop="clearHistory" src="@/assets/image/personalCenter/20220728-110000.png" alt="删除">
          </div>
          <div class="history-list">
            <span class="history-item" @click.stop="onLabelClick(item)" v-for="(item,index) in searchHistory" :key="index">{{item}}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 分类列表 -->
    <div class="searchType">
      <div class="o_type">
        <p class="title">商品类别：</p>
        <p class="type" @click="typestatus(item)" :class="{active: item.code == otypeCode}" v-for="(item, index) in shop_types" :key="index">{{ item.name }}</p>
      </div>
      <div class="o_type" v-if="child_types.length">
        <p class="title">细分类别：</p>
        <p class="type" :class="{active: paramsQuery.categoryCode == item.code}" @click="childtypestatus(item)" v-for="item in child_types" :key="item.id">{{ item.name }}</p>
      </div>
      <div class="o_type">
        <p class="title">费用情况：</p>
        <p class="type" @click="freeStutas(item)" :class="{active: paramsQuery.isFree == item.id}" v-for="(item, index) in free_types" :key="index">{{ item.name }}</p>
      </div>
    </div>

    <!-- 商品列表 -->
    <div class="shops">
      <no-message style="margin-top: 100px;" :msg="'暂无数据'" :imgSrc="require('@/assets/image/RevisionHome/shopsUn.png')" v-if="!total" type=1></no-message>
      <div class="search" v-show="total > 0">
        <div class="l_box">
          <div @click="sort(2)" class="sort" :class="{activetop: paramsQuery.sorting == 2}">
            <p>销量</p>
          </div>
          <div @click="sort(3)" class="sort" :class="{activefoot: paramsQuery.sorting == 3,activetop: paramsQuery.sorting == 4}">
            <p>价格</p>
            <div class="btn">
              <a-icon class="aIcon" type="caret-up" />
              <a-icon class="aIcon" type="caret-down" />
            </div>
          </div>
        </div>
        <p class="r_Total">共找到<span> {{total}} </span>个结果</p>
      </div>

      <div class="shopList">
        <div class="shop" v-for="item in productList" :key="item.id" @click="toproDetail(item)">
          <img :src="item.listPhoto" alt="" class="listPhoto">
          <div class="shopMess">
            <p class="name mallHidden">{{ item.name }}</p>
            <p class="shopStyle" v-if="item.type == 1">{{ item.courseCategory }} | {{ item.courseHour }}</p>
            <p class="shopStyle" v-if="item.type == 4">{{ item.answerNumber }}</p>
            <!-- <p class="teacherList" v-if="item.teacherList && item.teacherList.length"><span>主讲老师：</span><span v-for="i in item.teacherList" :key="i">{{ i }}</span></p> -->
            <!-- <p class="slogan" v-if="item.slogan">{{ item.slogan }}</p> -->
            <!-- <p class="bookAbstracts" v-if="item.abstracts && (item.type == 2 || item.type == 3)"><span class="tag">Editor's Pick</span> <span class="text" v-html="item.abstracts"></span></p> -->
            <!-- <p class="abstracts mallHidden" v-if="item.type == 1" v-html="item.abstracts"></p> -->
          </div>
          <div class="priceBox">
            <div class="price" v-if="item.couponPrice"><span>{{ item.couponPrice.toFixed(2).split('.')[0] }}</span><span>.{{ item.couponPrice.toFixed(2).split('.')[1] }}</span></div>
            <div class="price" v-else>免费</div>
            <div class="toDetail">查看详情 <a-icon class="aIcon" type="right-circle" theme="filled" /></div>
          </div>
        </div>
      </div>
      <a-pagination
        class="aPagination"
        v-model="paramsQuery.pageNum"
        show-size-changer
        :page-size.sync="paramsQuery.pageSize"
        :total="total"
        @showSizeChange="onShowSizeChange"
        hideOnSinglePage
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shop_types: [], // 商品分类
      child_types: [], // 子分类
      free_types: [
        {
          name: '全部',
          id: ''
        },
        {
          name: '付费',
          id: '0'
        },
        {
          name: '免费',
          id: '1'
        }
      ], // 是否收费
      total: 0,
      productList: [], // 商品列表
      otypeCode: '',
      paramsQuery: {
        pageNum: 1,
        pageSize:10,
        sorting: 1, // 排序 1创建创建时间 2销量，3价格降序，4价格升序
        categoryCode: '', // 分类id
        productName: '',
        isFree: '', // 是否免费 1是 0否
      },
      searchHistory: [],
      hotKeyList:[ '孤独症', '智力障碍', '行为分析', '岗位培训', 'VB-MAPP评估', '督导服务', '言语技能', '问题行为', '行为问题', '语言行为'], // 热门搜索关键词
      historyShow: false, // 历史搜索面板控制器
      loading:false,
    }
  },
  watch: {
    "paramsQuery.sorting"(newVal,oldVal){
      this.paramsQuery.pageNum = 1;
      this.getShopList();
    },
    'paramsQuery.pageNum'(val) {
      this.getShopList();
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
  },
  methods: {
    onShowSizeChange(current, pageSize) {
      this.paramsQuery.pageNum = current;
      this.paramsQuery.pageSize = pageSize;
      this.getShopList();
    },

    // 选择是否免费筛选条件
    freeStutas(e) {
      this.$set(this.paramsQuery,'isFree',e.id);
      this.paramsQuery.pageNum = 1;
      this.getShopList();
    },

    // 选择商品一级分类
    typestatus(e) {
      if(this.paramsQuery.categoryCode == e) {
        return
      }
      this.otypeCode = e.code;
      this.$set(this.paramsQuery,'categoryCode',e.code);
      this.paramsQuery.pageNum = 1;
      this.getShopList();
      if(e.children && e.children.length) {
        this.child_types = e.children;
      }else{
        this.child_types = [];
      }
    },

    // 选择商品二级分类
    childtypestatus(e) {
      if(this.paramsQuery.categoryCode == e) {
        return
      }
      this.$set(this.paramsQuery,'categoryCode',e.code);
      this.paramsQuery.pageNum = 1;
      this.getShopList();
    },

    // sort排序
    sort(e) {
      if(e == 3) {
        if(this.paramsQuery.sorting == 4) {
          this.$set(this.paramsQuery,'sorting',3);
        }else if(this.paramsQuery.sorting == 3) {
          this.$set(this.paramsQuery,'sorting',1);
        }else {
          this.$set(this.paramsQuery,'sorting',4);
        }
        return
      }
      if(e == this.paramsQuery.sorting) {
        this.$set(this.paramsQuery,'sorting',1);
        return
      }
      this.$set(this.paramsQuery,'sorting',e);
    },

    // 跳转商品详情
    toproDetail(item) {
      if (item.productType == 1 || item.type == 1) {
        this.$router.push({
          path: "/knowledgeMall/courseDetail",
          query: {
            productId: this.$AES.encode_data(item.productId + ""),
            code: item.code ? item.code : item.productCode,
          },
        });
      } else if (
        item.productType == 2 ||
        item.productType == 3 ||
        item.type == 2 ||
        item.type == 3
      ) {
        this.$router.push(
          "/knowledgeMall/curriculumDetail?productId=" +
            this.$AES.encode_data(item.productId + "")
        );
      } else if (item.productType == 4 || item.type == 4) {
        this.$router.push(
          "/knowledgeMall/examDetail?productId=" +
            this.$AES.encode_data(item.productId + "")
        );
      }
    },

    // 获取商品分类
    getTypeList() {
      this.$ajax({
        url: "/hxclass-pc/pc-mall/pc/product-category",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          let arr = [{name:'全部', code: ''}];
          res.data.map((item) => {
            if(item.code == this.otypeCode) {
              this.child_types = item.children;
            }
            if (item.children && item.children.length) {
              item.children.map((ite, ind) => {
                if (ite.productList && ite.productList.length) {
                } else {
                  item.children.splice(ind, 1);
                }
              });
              arr.push(item);
            } else {
              arr.push(item);
            }
          });
          arr = arr.filter((item) => {
            return (
              item.name == '全部' ||
              (item.children && item.children.length) ||
              (item.productList && item.productList.length)
            );
          });
          this.shop_types = arr;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    onLabelClick(name){
      this.paramsQuery.productName = name
      this.onButtonSearch()
    },

    clearHistory(){
      this.searchHistory = []
      localStorage.setItem('searchHistory', null)
    },

    onButtonSearch(){
      if(!this.paramsQuery.productName){
        this.paramsQuery.productName = '孤独症康复教育'
      }

      if (this.paramsQuery.productName.trim() !== '') {
        // 如果新关键词已经在历史中，则先移除它
        const index = this.searchHistory.indexOf(this.paramsQuery.productName);
        if (index > -1) {
          this.searchHistory.splice(index, 1);
        }
        
        // 确保历史记录不超过10条
        if (this.searchHistory.length >= 8) {
          this.searchHistory.pop(); // 移除最后一项
        }
        
        // 在数组最前面添加新关键词
        this.searchHistory.unshift(this.paramsQuery.productName);
      }

      localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory))

      this.getShopList()

      setTimeout(()=>{
        this.historyShow = false
      }, 50)
    },

    clearInput(e){
      const newValue = e.target.value
      if(!newValue){
        this.getShopList()
      }
    },

    // 获取商品列表
    getShopList() {
      this.loading = true
      this.$ajax({
        url: '/hxclass-pc/pc-mall/revisionAppCategoryList',
        params: this.paramsQuery
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.total = res.data.total;
          this.productList = res.data.records;
        }
        this.loading = false
      })
    }
  },
  created() {
    if(this.$route.query.tabCode) {
      this.otypeCode = this.$route.query.code;
      this.paramsQuery.categoryCode = this.$route.query.tabCode;
    }else if(this.$route.query.code){
      this.otypeCode = this.$route.query.code;
      this.paramsQuery.categoryCode = this.$route.query.code;
    }
    if(this.$route.query.productName){
      this.paramsQuery.productName = this.$route.query.productName
    }
    this.getTypeList();
    this.getShopList();

    if(JSON.parse(localStorage.getItem('searchHistory'))){
      this.searchHistory = JSON.parse(localStorage.getItem('searchHistory'))
    }
  }
}
</script>

<style lang="less" scoped>
  .main-box {
    padding-top: 80px;
    width: 100%;
    .searchBtn,
    .aBreadcrumb,
    .searchType,
    .search,.shopList{
      width: 1200px;
      margin: 0 auto;
    }
    .aPagination{
      text-align: center;
    }
    .searchBtn{
      position: relative;
      margin-top: 20px;
      .search-box{
        width: 400px;
        display: flex;
        padding: 3px 7px 3px 7px;
        border: 1px solid #15B7DD;
        border-radius: 8px;
        /deep/ .ant-input{
          height: 32px;
          border: none;
          background-color: #F6F6FC;
        }
        /deep/ .ant-input:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .history-box{
        position: absolute;
        left: 0;
        top: 50px;
        transition: .3s;
        height: 0;
        width: 400px;
        background: #FFFFFF;
        box-shadow: 4px 4px 10px 0px rgba(0,0,0,0.1);
        border-radius: 8px 8px 8px 8px;
        overflow: hidden; /* 超出部分隐藏 */
        .title{
          font-weight: 500;
          font-size: 14px;
          color: #15B7DD;
          line-height: 21px;
        }
        .label{
          .label-item{
            display: inline-block;
            background: #F6F6FC;
            margin-right: 20px;
            margin-top: 10px;
            padding: 0 10px;
            border-radius: 11px;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 21px;
            cursor: pointer;
          }
          .label-active{
            color: #15B7DD;
          }
        }
        .history-title{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          span{
            color: #333333;
          }
          img{
            width: 18px;
            cursor: pointer;
          }
        }
        .history-list{
          .history-item{
            display: inline-block;
            margin-right: 20px;
            margin-top: 10px;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 21px;
            cursor: pointer;
          }
        }
      }
      .history-box-open{
        height: 260px;
      }
    }
    .shopList{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 18px;
      .shop{
        width: calc(50% - 15px);
        padding: 12px;
        background: #FFFFFF;
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 10px;
        margin-bottom: 24px;
        display: flex;
        cursor: pointer;
        .priceBox{
          padding-right: 12px;
          text-align: right;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 152px;
          .toDetail{
            margin-top: 12px;
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #666666;
            line-height: 24px;
            cursor: pointer;
            .aIcon{
              color: #8ADBEE;
            }
          }
          .price{
            display: inline-block;
            font-size: 24px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #EC6C01;
            line-height: 36px;
            span:first-child{
              position: relative;
            }
            span:first-child::before{
              content: '￥';
              position: absolute;
              left: -12px;
              bottom: 3px;
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #EC6C01;
              line-height: 18px;
            }
            span:nth-child(2){
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #EC6C01;
              line-height: 21px;
            }
          }
        }
        .shopMess{
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1;
          .name{
            font-size: 18px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 27px;
            cursor: pointer;
          }
          .shopStyle{
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #15B7DD;
            line-height: 18px;
          }
          .abstracts{
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 18px;
          }
          .teacherList{
            display: flex;
            align-items: center;
            margin-top: 6px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 21px;
            span{
              margin-right: 10px;
            }
            span:first-child{
              margin-right: 6px;
            }
          }
          .slogan{
            margin-top: 24px;
            font-size: 13px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
          }
          .bookAbstracts{
            margin-top: 20px;
            .tag{
              padding-left: 4px;
              padding-right: 17px;
              background: linear-gradient(225deg, #FFBB64 0%, #FE9B3F 100%);
              font-size: 13px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 20px;
              position: relative;
            }
            .tag::after{
              content: '';
              width: 0;
              height: 0;
              border-bottom: 20px solid white;
              border-left: 20px solid transparent;
              position: absolute;
              top: 0;
              right: 0;
            }
            span{
              font-size: 13px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 20px;
            }
          }
        }
        .listPhoto{
          width: 110px;
          height: 110px;
          border-radius: 8px;
        }
      }
      .shop:hover{
        box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.1);
        border-color: #FFFFFF;
      }
    }
    .searchType{
      margin-top: 18px;
    }
    .o_type{
      display: flex;
      align-items: center;
      margin-top: 21px;
      .title {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 21px;
      }
      .type{
        margin-left: 12px;
        width: 80px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        text-align: center;
        cursor: pointer;
        user-select: none;
      }
      .type:hover,.active{
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        background: #EAEAEF;
        border-radius: 6px 6px 6px 6px;
      }
    }
    .o_type:first-child{
      margin-top: 0;
    }
    .shops{
      margin-top: 16px;
      background: #FFFFFF;
      padding-top: 20px;
      padding-bottom: 50px;
      min-height: calc(100vh - 482px);
      /deep/.noMessage{
        img{
          width: 185px;
          height: 102px;
          margin-left: -3px;
        }
        p{
          margin-top: 10px;
        }
      }
      .search{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .r_Total{
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        span{
          font-weight: 500;
          color: #333333;
        }
      }
      .l_box{
        display: flex;
        .sort{
          margin-right: 19px;
          display: flex;
          align-items: center;
          cursor: pointer;
          p{
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 21px;
            user-select: none;
          }
          .btn{
            display: flex;
            flex-direction: column;
            justify-content: center;
            .aIcon{
              font-size: 10px;
              color: #D9D9D9;
            }
            .aIcon:first-child{
              margin-bottom: -3px;
            }
          }
        }
        .activetop{
          p{color: @theme;}
          .btn{
            .aIcon:first-child{
              color: @theme;
            }
          }
        }
        .activefoot{
          p{color: @theme;}
          .btn{
            .aIcon:nth-child(2){
              color: @theme;
            }
          }
        }
      }
    }
  }
</style>